import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import sesameOne from '../../assets/images/sesame/new/sesame1.webp';
import sesameTwo from '../../assets/images/sesame/new/sesame-2.webp';

import sesameTwoA from '../../assets/images/sesame/new/sesame2-a.webp';
import sesameTwoB from '../../assets/images/sesame/new/sesame2-b.webp';
import teamImage from '../../assets/images/sesame/new/team-image.webp';
import sesameBanner from '../../assets/images/sesame/sesame-banner.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class Sesame extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title section-margin p-0">
                  Mobile Apps for Classic Animated Storybooks
                </div>
                <div className="subtitle section-margin">
                  Updating the popular apps to make them available across mobile devices, tablets
                  and select smart TVs
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#E9B238'}}> SESAME</span>
                  <span style={{color: '#00AF42'}}> WORKSHOP</span>
                </div>

                <div className="para-mini-2 section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Update a set of popular digital books that were live on app stores but had not
                      been updated in a while.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Reviewed and updated the legacy technology for optimized performance on the
                      latest devices.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://sesameworkshop.org/about-us/press-room/sesame-street-celebrates-all-things-furry-lovable-and-brave-furrymonsters/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sesame Street celebrates all things furry, lovable, and brave with
                      #FurryMonsters fan activities, ahead of upcoming animated special
                    </a>{' '}
                    - Press Release
                  </p>
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      <a
                        className="description"
                        style={{textDecoration: 'underline'}}
                        href="https://sesameworkshop.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sesame Workshop
                      </a>
                      , an American nonprofit organization responsible for the production of several
                      educational children’s programs – including its first and best-known, Sesame
                      Street – was looking to update some of its popular digital books.
                    </p>
                    <br />
                    <p className="description">
                      The Monster at the End of this Book and Another Monster at the End of this
                      Book were two of the most popular titles ever published by Sesame, both in
                      their physical and digital forms. The two books had been on the app stores in
                      legacy technologies since 2018 and Sesame was looking to refresh these to
                      prolong their shelf life.
                    </p>
                    <br />
                    <p className="description">
                      Tintash was engaged by Sesame Workshop in 2020 to work on this project.
                    </p>
                    <br />
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        The Main Menu Screen - Another Monster at the End of this Book
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={sesameOne} alt="jobbox" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Through an RFP process, our team diligently evaluated the legacy code and art
                      assets and concluded that it was best to port the apps from the existing
                      cocos2d-x framework to Unity. Besides enabling smoother performance across the
                      latest iOS and Android phones and tablets, this would allow incorporation of
                      key contemporary features such as the analytics support and integration of
                      relevant thirdparty services.
                    </p>
                    <br />
                    <p className="description">
                      We devised the project plan following a careful review of the existing art
                      assets and animations. The art assets had to be re-used with the highest
                      fidelity to stay true to the Sesame brand. At the same time, the animations
                      had to be perfectly timed via careful engineering and testing since all the
                      interactive elements in the digital books followed strict pedagogical
                      guidelines that could not be compromised.
                    </p>
                    <br />
                    <p className="description">
                      Our engineers identified at the outset that a fair amount of the architecture
                      could be re-used between the two books and hence planned accordingly. Since
                      the complexity of art and animations could vary greatly across pages, we
                      developed each page of the books meticulously using the Spine tool. The team
                      alternated between automating the animations where it was practical and manual
                      development where it was required in order to stay true to the original
                      product.
                    </p>
                    <br />
                    <p className="description">
                      We brought these properties to Amazon Fire TV as well, the first of such
                      efforts by Sesame. This was a key component of the launch plan and involved
                      setting up carefully choreographed interactions across that hardware.
                    </p>
                    <br />
                    <p className="description">
                      The team worked on a tight timeline to have the app updates ready in time for
                      Sesame’s marketing campaign of their{' '}
                      <a
                        className="description"
                        style={{textDecoration: 'underline'}}
                        href="https://sesameworkshop.org/about-us/press-room/sesame-streets-51st-season-launches-thursday-november-12-hbo-max/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        HBO launch in November, 2020
                      </a>
                      . We prioritized and delivered a set of essential pre-launch tasks to ensure
                      the app was live for the big event, following that up with some post-launch
                      items.
                    </p>
                    <br />

                    <p className="description">
                      The final products live on the app stores were tightly integrated with the
                      Sesame APIs, including their cross-promotional efforts, and were hence robust
                      to serve in the foreseeable future. We at Tintash take great pride in having
                      contributed to Sesame’s mission of educating children and ensuring the goal
                      was not compromised in a year marred by the Covid challenges.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    {/* <div className="col-lg-3 p-0 d-flex align-items-center">
                      <div className="image-description text-left mb-0">
                        The Main Menu Screen - Another Monster at the End of this Book
                      </div>
                    </div> */}
                    <div className="col-lg-12 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={sesameTwo} alt="jobbox" />
                    </div>
                    <div className="image-description wrap-caption-sesame-left">
                      Another Monster at <br />
                      the End of this Book
                    </div>
                    <div className="image-description wrap-caption-sesame-right">
                      Some In-Game Screens
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      Through the course of this project, we worked closely with Sesame’s product
                      team. We would have weekly meetings to discuss progress and prioritize
                      features, documenting those discussions using shared project management tools
                      such as the Jira board to ensure both the teams were perfectly aligned.
                    </p>
                  </div>
                  <div className="text-wrap-container col-12 p-0 d-flex section-margin">
                    <div className="col-lg-3 p-0 d-flex align-items-center justify-content-center">
                      <div className="image-description text-left mb-0 pr-5">
                        Team behind the project
                      </div>
                    </div>
                    <div className="col-lg-9 p-0 d-flex justify-content-center">
                      <img className="wrap-image" src={teamImage} alt="jobbox" />
                    </div>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default Sesame;
